import { createGlobalStyle } from 'styled-components';
import tw from 'tailwind.macro';

export default createGlobalStyle`
  h5, h6 {
    ${tw`font-medium mt-4 mb-2`};
  }

  h5 {
    ${tw`text-xl`}
  }

  h6 {
    ${tw`text-base`}
  }

  ul {
    ${tw`list-disc list-inside my-4`}
  }

  ol {
    ${tw`list-decimal list-inside my-4`}
  }

  ul ul,
  ul ol,
  ol ol,
  ol ul {
    ${tw`my-4 ml-12`}
  }

  li {
    ${tw`mb-1`}
  }

  .gatsby-highlight {
    background-color: #2d2d2d;
    border-radius: 0.3em;
    margin: 0.5em 0;
    padding: 1em;
    overflow: auto;
    width: 90vw;
    ${tw`max-w-screen-md`}
  }

  .gatsby-highlight pre[class*="language-"].line-numbers {
    padding: 0;
    padding-left: 2.8em;
    overflow: initial;
  }

`;